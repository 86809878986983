<template>
  <div class="unit-list">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="单位管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 单位名称 -->
        <ml-input
          prop="companyNameLike"
          placeholder="请输入阿米巴"
          style="margin-bottom: 0; margin-right: 40px"
          label="阿米巴："
          v-model="searchData.companyNameLike"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 新增按钮 -->
        <ml-button
          v-if="isBusinessDepartment"
          :showSubmit="false"
          cancelText="新增阿米巴"
          cancelIcon="el-icon-plus"
          cancelType="primary"
          @click-cancel="clickPush"
          style="margin-bottom: 0"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="阿米巴" prop="companyName" min-width="20%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="单位地址"
          prop="address"
          min-width="50%"
        >
          <template #default="scope">
            {{ `${scope.row.province}${scope.row.city}${scope.row.area}${scope.row.address}` }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="负责人" prop="companyUser" min-width="20%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="服务描述"
          prop="serviceDesc"
          min-width="20%"
        />
        <el-table-column align="center" label="操作" priop="companyId" min-width="20%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              v-if="isBusinessDepartment"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除单位提示框 -->
    <ml-dialog
      ref="deleteUnitDialogRef"
      :content="deleteUnitData.content"
      @click-submit="submitDeleteUnit"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'UnitManage',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      companyNameLike: getMemoryPage.value.searchData.companyNameLike || ''
    })

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getUnitTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getUnitTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })
    // 获取数据列表
    const getUnitTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.companyNameLike = replacePerCent(searchDataParams.companyNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetCompanyInfos',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getUnitTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 添加单位
    const clickPush = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/unitAction' })
    }
    // 修改单位
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/unitAction', query: { id: row.companyId } })
    }
    // 删除单位
    const deleteUnitDialogRef = ref()
    const deleteUnitData = reactive({
      content: '确定删除该单位？'
    })
    const clickDelete = (index, row) => {
      deleteUnitData.id = row.companyId
      deleteUnitDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUnit = () => {
      const { id } = deleteUnitData
      dispatch('fetchDeleteCompanyInfo', id).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getUnitTabberData() : (tabberData.page = 1)
          deleteUnitDialogRef.value.showDialog = false
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(() => {
      getUnitTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickPush,
      clickUpdate,
      clickDelete,
      deleteUnitData,
      deleteUnitDialogRef,
      submitDeleteUnit,
      isBusinessDepartment
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-list {
  @extend %params-global;
}
</style>
